import React from "react"
import Header from "./Header"

const PpcLayout = ({ children }) => {
  // const [state, setState] = useState(false)

  // const Footer =
  // state && React.lazy(() => import("../../components/common/Footer"))

  return (
    <div style={{ backgroundColor: `#FAFDFF` }}>
      <Header />
      <main>{children}</main>
      {/* <Footer/> */}
    </div>
  )
}

export default PpcLayout
