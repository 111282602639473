// extracted by mini-css-extract-plugin
export var btn = "HireByRole-module--btn--46694";
export var contentSection = "HireByRole-module--contentSection--21c5b";
export var contentSectionMobile = "HireByRole-module--contentSectionMobile--712da";
export var desc = "HireByRole-module--desc--81f99";
export var header = "HireByRole-module--header--e1e7b";
export var img = "HireByRole-module--img--46b2a";
export var img1 = "HireByRole-module--img1--b8ed7";
export var img2 = "HireByRole-module--img2--8c319";
export var img3 = "HireByRole-module--img3--ac5ac";
export var img4 = "HireByRole-module--img4--613c6";
export var img5 = "HireByRole-module--img5--6a728";
export var img6 = "HireByRole-module--img6--fa4fb";
export var section = "HireByRole-module--section--416cc";