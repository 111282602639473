import { graphql } from "gatsby"
import React, { useState } from "react"
import Banner from "../../components/ppc-landing/Banner"
import BannerMobile from "../../components/ppc-landing/PPCMobile"
import TrustInvoZone from "../../components/ppc-landing/TrustInvoZone"
import ConsultingServices from "../../components/ppc-landing/ConsultingServices"
import TechSlider from "../../components/ppc-landing/TechStack"
import TechStackMobile from "../../components/ppc-landing/TechStackMobile"
import Preferences from "../../components/ppc-landing/Preferences"
import Stories from "../../components/ppc-landing/Stories"
import Hitting from "../../components/ppc-landing/Hitting"
import Transformation from "../../components/ppc-landing/Transformation"
import OurServices from "../../components/ppc-landing/OurServices"
import HireByRole from "../../components/ppc-landing/HireByRole"
import KickingOff from "../../components/ppc-landing/KickingOff"
import Trial from "../../components/ppc-landing/Trial"
import Every from "../../components/ppc-landing/Every"
import Footer from "../../components/common/Footer"
import PpcLayout from "../../components/ppc/PpcLayout"
import "./contactUs.scss"

const Ppc = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const trust = data?.strapiPage?.sections[1]
  const stories = data?.strapiPage?.sections[2]
  const kick = data?.strapiPage?.sections[3]
  const trial = data?.strapiPage?.sections[4]
  const role = data?.strapiPage?.sections[5]
  const inspire = data?.strapiPage?.sections[6]
  const consultingServices = data?.strapiPage?.sections[7]
  const every = data?.strapiPage?.sections[8]
  const preferences = data?.strapiPage?.sections[9]
  const techSlider = data?.strapiPage?.sections[10]
  const hitting = data?.strapiPage?.sections[11]
  const transformation = data?.strapiPage?.sections[12]

  return (
    <PpcLayout>
      <div id="ppc_form">
        <div className="mobile-ppc">
          <BannerMobile strapiData={heroSection} />
        </div>
        <div className="home-ppc">
          <Banner strapiData={heroSection} />
        </div>
      </div>
      <TrustInvoZone strapiData={trust} />
      <Stories strapiData={stories} />
      <KickingOff strapiData={kick} bg={"#fff"} />
      <Trial strapiData={trial} />
      <HireByRole strapiData={role} />
      <OurServices strapiData={inspire} />
      <ConsultingServices strapiData={consultingServices} />
      <Every strapiData={every} />
      <Preferences strapiData={preferences} />
      {!isMobile && !isTablet ? (
        <TechSlider strapiData={techSlider} />
      ) : (
        <TechStackMobile strapiData={techSlider} />
      )}
      <Hitting strapiData={hitting} />
      <Transformation strapiData={transformation} />
      <Footer ppc={true} />
    </PpcLayout>
  )
}

export const query = graphql`
  query ppcLanding {
    strapiPage(slug: { eq: "ppc-landing" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
    }
  }
`

export default Ppc

export const Head = () => {
  return (
    <>
      <script
        type="text/javascript"
        src="https://widget.clutch.co/static/js/widget.js"
      ></script>
      <title>Hire Top Rated Software Engineers - InvoZone</title>
      <meta
        name="description"
        content="Get seasoned software engineers trusted by top CTOs and VPs of Engineering. Our teams deliver innovative and reliable software solutions tailored to your business needs"
      />
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}
