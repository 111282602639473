import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import tick from "../../images/ppc-update/check-blue.svg"
import * as styles from "./Hitting.module.scss"

const data = [
  {
    title: "300+",
    dec: "Successful Projects",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/successful_project_b3896ae49d.svg",
  },
  {
    title: "97%",
    dec: "Success Rate",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/success_rate_457a1d6f85.svg",
  },
  {
    title: "500+",
    dec: "Team & Professionals",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/team_and_professionals_36678e5e27.svg",
  },
  {
    title: "10+",
    dec: "Year of Experiences",
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/years_of_experience_7ab096c6f0.svg",
  },
]

const Hitting = ({ strapiData }) => {
  return (
    <div className={`${styles.empowerCyberTypescript}`}>
      <Container>
        <Row className="gap-30 align-items-center">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className={styles.hire}>
              <h2
                className={`${styles.hireHeading} `}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </div>
            <div className={styles.cards}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={styles.talent} key={index}>
                    <img className={styles.tick} src={tick} alt={item?.title} />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <Row className={`gap-30 justify-content-start`}>
              {data?.map((e, i) => (
                <Col xl={6} md={6} xs={12} key={i} className="gap-30 d-flex">
                  <div className={styles.card}>
                    <img
                      src={e?.img}
                      alt="icon"
                      decoding="async"
                      loading="lazy"
                    />
                    <h3>{e?.title}</h3>
                    <p>{e?.dec}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Hitting
