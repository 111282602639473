// extracted by mini-css-extract-plugin
export var attachment = "PPCForm-module--attachment--f1a20";
export var attachmentLabel = "PPCForm-module--attachmentLabel--c33d3";
export var errorTxt = "PPCForm-module--errorTxt--5926b";
export var fieldColor = "PPCForm-module--fieldColor--29d29";
export var formControl = "PPCForm-module--form-control--807f4";
export var formInner = "PPCForm-module--formInner--79d4c";
export var hr = "PPCForm-module--hr--b00fd";
export var newlinks = "PPCForm-module--newlinks--6ccae";
export var productHead = "PPCForm-module--productHead--79cbb";
export var query = "PPCForm-module--query--83ddf";
export var salesFormCheckBoxSize = "PPCForm-module--salesFormCheckBoxSize--3c522";
export var salesFormRowHeight = "PPCForm-module--salesFormRowHeight--09364";