import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import mernrightArrow from "../../images/hireMernStack/right-arrow.svg"
import mernLeftArrow from "../../images/hireMernStack/left-arrow.svg"
import * as styles from "./OurServices.module.scss"
import "./OurServices.scss"
import { Link } from "gatsby"

const PortfolioCards = [
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Column_f43bdcbda3.png",
    title: "Column",
    description:
      "Public notice platform helps journalists, governments, and legal services.",
    portfolioTags: ["Online Media", "Journalism", "Public Information"],
    series: "Series A:",
    value: "$30 M",
    button: "/portfolio/column/",
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Homepie_07cf9486ee.png",
    title: "Homepie",
    description: "Build an Online Marketplace to Connect Buyers & Sellers.",
    portfolioTags: ["Real Estate", "Marketplace", "e-Commerce"],
    series: "Revenue Range:",
    value: "$10 M",
    button: "/portfolio/homepie/",
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Global_Reader_cbf6689b27.png",
    title: "GlobalReader",
    description: "Real-time Data for Tracking Production Capacity.",
    portfolioTags: ["Industrial", "IT", "Manufacturing"],
    series: "Seed:",
    value: "$171.4K",
    button: "/portfolio/globalreader/",
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Stitch_Health_c3aab1cff8_216b1a71a2.png",
    title: "Stitch.Health",
    description: "Central Messaging Platform for Healthcare Providers.",
    portfolioTags: ["Healthcare", "Internet", "AI ChatBot"],
    button: "/portfolio/stitch-health/",
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Theraforge_6f70b36b55_3070b20af7.png",
    title: "Theraforge",
    description:
      "Hippocrates Tech is the world’s first multi-cloud digital health laboratory.",
    portfolioTags: ["Healthcare", "Digital Health", "Health"],
    button: "/portfolio/theraforge/",
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Big_Shooter_Golf_fe0e9847b7_c26f3edca0.png",
    title: "BigShooterGolf",
    description:
      "Big Shooter Golf combines video games and fantasy sports leagues.",
    portfolioTags: ["On-Demand", "Simulation Game", "Gaming"],
    button: "/portfolio/big-shooter-golf/",
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/App_Work_f1db62765f_42cd7d0189.png",
    title: "AppWork",
    description:
      "AppWork simplifies work order creation and management solutions.",
    portfolioTags: ["On-Demand", "Digital Health", "PropTech", "BI"],
    button: "/portfolio/appwork/",
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/Sheild_Republic_a4ab71bdcc_eb03171988.png",
    title: "Shield Republic",
    description: "e-Commerce Platform for reinventing the customer experience.",
    portfolioTags: ["e-Ecommerce", "Apparel", "Marketplace"],
    button: "/portfolio/shield-republic/",
  },
]

const OurServices = ({ strapiData }) => {
  function Arrow(props) {
    const { onClick } = props
    return (
      <div className={styles.reactJsIconContainer}>
        <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
          <img
            src={mernrightArrow}
            onClick={onClick}
            className={styles.portfolioArrowIcon}
            decoding="async"
            loading="lazy"
            alt="arrow"
          />
        </div>
      </div>
    )
  }
  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className={styles.reactJsIconContainerLeft}>
        <div className={styles.portfolioArrowIconCover} onClick={onClick}>
          <img
            src={mernLeftArrow}
            className={styles.portfolioArrowIcon}
            decoding="async"
            loading="lazy"
            alt="arrow"
          />
        </div>
      </div>
    )
  }

  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    ;(current === strapiData?.cards.length - 3 ||
      current_1 === strapiData?.cards.length - 2 ||
      current_2 === strapiData?.cards.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)
  }, [current, current_1, current_2])

  const settings = {
    autoplay: true,
    dots: true,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    infinite: true,
    speed: 200,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
    customPaging: function (i) {
      // console.log(i)
      return (
        <div
          style={{
            width: "100%",
            background: "#F2F2F2",
            borderRadius: "2px",
            marginTop: "102px",
            position: "absolute",
            height: "4px",
            // left: "-5.8rem",
            left: 0,
            zIndex: 1,
            // marginRight: "500px",
          }}
        ></div>
      )
    },
  }

  return (
    <React.Fragment>
      <div className={styles.mainOurServices}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={` ${styles.cardWrapper}`}>
            <Slider
              {...settings}
              className={`${styles.SliderWidth} mainSliderOurService`}
              ref={slide1}
            >
              {PortfolioCards?.map((e, i) => (
                <Col key={i}>
                  <div className={styles.card}>
                    <div className={styles.cardImgTopPortfolio}>
                      <img
                        src={e?.img}
                        alt=""
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                    <div className={styles.cardContentPortfolio}>
                      <div
                        className={`d-block d-sm-flex  ${styles.cardHeader}`}
                      >
                        <div>
                          <h3>{e?.title}</h3>
                        </div>
                        <div className={styles.bgRemove}>
                          <span
                            className={
                              i === 0
                                ? styles.spanCardHeader1
                                : i === 1
                                ? styles.spanCardHeader2
                                : i === 2
                                ? styles.spanCardHeader3
                                : ""
                            }
                          >
                            {e?.series}
                            <span className={styles.spanCardHeaderColorChange}>
                              {e?.value}
                            </span>
                          </span>
                        </div>
                      </div>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.description,
                        }}
                      />
                      <hr />
                      <div className={styles.tagsOuter}>
                        {e.portfolioTags.map((tagPortfilio, index) => (
                          <span key={index} className={styles.tag}>
                            {tagPortfilio}
                          </span>
                        ))}
                      </div>
                      <div className={styles.detailsLink}>
                        <Link to={e?.button} className={styles.portfolioBtn}>
                          View Project Details
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OurServices
