// extracted by mini-css-extract-plugin
export var KickImage = "KickingOff-module--KickImage--f8581";
export var act = "KickingOff-module--act--991ba";
export var active = "KickingOff-module--active--0ab8b";
export var contentContainer = "KickingOff-module--contentContainer--d0674";
export var desc = "KickingOff-module--desc--dc712";
export var deskImg = "KickingOff-module--deskImg--67ee9";
export var header = "KickingOff-module--header--a76bd";
export var mobileImg = "KickingOff-module--mobileImg--46ebc";
export var processCard = "KickingOff-module--processCard--218fe";
export var section = "KickingOff-module--section--67c6f";
export var subDesc = "KickingOff-module--subDesc--eded0";
export var subHeading = "KickingOff-module--subHeading--fd07a";