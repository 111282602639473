// extracted by mini-css-extract-plugin
export var bannerSubtitle = "Banner-module--bannerSubtitle--91baa";
export var buttonPadding = "Banner-module--buttonPadding--7a429";
export var claim = "Banner-module--claim--09c05";
export var content = "Banner-module--content--8a5a4";
export var dec = "Banner-module--dec--7eeb0";
export var lineer = "Banner-module--lineer--43a38";
export var list = "Banner-module--list--1e6e0";
export var mobileHeading = "Banner-module--mobileHeading--dc0dc";
export var section = "Banner-module--section--39ff7";
export var talent = "Banner-module--talent--b9574";
export var text = "Banner-module--text--64620";
export var videoHeight = "Banner-module--videoHeight--60a41";