// extracted by mini-css-extract-plugin
export var SliderWidth = "OurServices-module--SliderWidth--cf5c6";
export var arrowImg = "OurServices-module--arrowImg--b4dc0";
export var bgRemove = "OurServices-module--bgRemove--59b78";
export var card = "OurServices-module--card--d06e8";
export var cardContentPortfolio = "OurServices-module--cardContentPortfolio--28b97";
export var cardHeader = "OurServices-module--cardHeader--87917";
export var cardImg = "OurServices-module--cardImg--81578";
export var cardImgTopPortfolio = "OurServices-module--cardImgTopPortfolio--caf39";
export var cardWrapper = "OurServices-module--cardWrapper--11bfc";
export var cards = "OurServices-module--cards--66f1b";
export var description = "OurServices-module--description--54f8d";
export var detailsLink = "OurServices-module--detailsLink--cd43a";
export var heading = "OurServices-module--heading--d8c2d";
export var headingPremium = "OurServices-module--headingPremium--2eaff";
export var mainOurServices = "OurServices-module--mainOurServices--a6cba";
export var portfolioArrowIcon = "OurServices-module--portfolioArrowIcon--72c6b";
export var portfolioArrowIconCover = "OurServices-module--portfolioArrowIconCover--df655";
export var portfolioArrowRightIconCover = "OurServices-module--portfolioArrowRightIconCover--9e8c3";
export var portfolioBtn = "OurServices-module--portfolioBtn--d7c13";
export var premiumImg = "OurServices-module--premiumImg--87923";
export var providingImg = "OurServices-module--providingImg--5eced";
export var reactJsIconContainer = "OurServices-module--reactJsIconContainer--cecf5";
export var reactJsIconContainerLeft = "OurServices-module--reactJsIconContainerLeft--84557";
export var spanCardHeader1 = "OurServices-module--spanCardHeader1--a4037";
export var spanCardHeader2 = "OurServices-module--spanCardHeader2--ce39c";
export var spanCardHeader3 = "OurServices-module--spanCardHeader3--906aa";
export var spanCardHeaderColorChange = "OurServices-module--spanCardHeaderColorChange--39cec";
export var tag = "OurServices-module--tag--04cb7";
export var tagsOuter = "OurServices-module--tagsOuter--8963d";
export var teamButton = "OurServices-module--teamButton--5701a";