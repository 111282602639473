// extracted by mini-css-extract-plugin
export var active = "Stories-module--active--5518c";
export var columnImgTop = "Stories-module--columnImgTop--6c606";
export var columnTopBannerImg = "Stories-module--columnTopBannerImg--611fa";
export var columnTopBannerImg2 = "Stories-module--columnTopBannerImg2--1ee05";
export var desc = "Stories-module--desc--74edf";
export var header = "Stories-module--header--91366";
export var imgContainer = "Stories-module--imgContainer--5e894";
export var infoContainer = "Stories-module--infoContainer--ce1ba";
export var modalBodytestiRevamp = "Stories-module--modalBodytestiRevamp--5fc25";
export var modalContentTestiRevamp = "Stories-module--modalContentTestiRevamp--cccc9";
export var playIconColumn = "Stories-module--playIconColumn--ae4bf";
export var role = "Stories-module--role--54f17";
export var section = "Stories-module--section--a1971";
export var storyCard = "Stories-module--storyCard--ae8cb";
export var storyCardWrapper = "Stories-module--storyCardWrapper--befce";