import React, { useEffect } from "react"
import * as styles from "./Every.module.scss"
import "./OurServices.scss"
import Container from "react-bootstrap/Container"

const Every = ({ strapiData }) => {
  useEffect(() => {}, [])

  return (
    <div className={styles.Every}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div style={{ maxWidth: 915, margin: "auto" }}>
          <div className="ppcWidget">
            <div
              class="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="8"
              data-height="500"
              data-nofollow="true"
              data-expandifr="true"
              data-scale="100"
              data-reviews="261467,257226,245391,233930,211206,198909"
              data-clutchcompany-id="1449314"
            ></div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Every
