import React from "react"
import * as styles from "./TechStack.module.scss"
import Container from "react-bootstrap/Container"
import Slider from "react-slick"

const TechStack = ({ strapiData }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 7,
    speed: 1000,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }
  const settings2 = {
    infinite: true,
    autoplay: true,
    slidesToShow: 7,
    speed: 1000,
    dots: false,
    arrows: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  // console.log(strapiData?.cards)

  return (
    <div className="text-center">
      <div className={styles.Trust}>
        <React.Fragment>
          <Container>
            <h2
              className={`${styles.heading} `}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </Container>

          <Slider {...settings} className={styles.devOpsRefineImages}>
            {strapiData?.cards?.map(
              (e, i) =>
                i < 27 && (
                  <div key={i} className={styles.portfoliosLogos}>
                    <img
                      src={e?.image1[0]?.localFile?.publicURL}
                      alt={e?.image1[0]?.alternativeText}
                      decoding="async"
                      loading="lazy"
                      height={60}
                      width={60}
                    />
                  </div>
                )
            )}
          </Slider>
          <div className={styles.slider2}>
            <Slider {...settings2} className={styles.devOpsRefineImages2}>
              {strapiData?.cards?.map(
                (e, i) =>
                  i > 27 && (
                    <div>
                      <div key={i} className={styles.portfoliosLogos}>
                        <img
                          src={e?.image1[0]?.localFile?.publicURL}
                          alt={e?.image1[0]?.alternativeText}
                          decoding="async"
                          loading="lazy"
                          height={60}
                          width={60}
                        />
                        <h3>{e?.title}</h3>
                      </div>
                    </div>
                  )
              )}
            </Slider>
          </div>
        </React.Fragment>
      </div>
      <img
        className={styles.bottomLine}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_fc76aa81fe.svg"
        alt="icon"
      />
    </div>
  )
}

export default TechStack
