import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./Trial.module.scss"

const Trial = ({ strapiData }) => {
  return (
    <div className={styles.readyHuntFinestIos}>
      <Container className={styles.pad}>
        <div className={styles.readyHuntBg}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <div className={`${styles.bannerBtn}`}>
            {strapiData?.buttons[0] && (
              <Link to="#ppc_form" className={styles.btn_white2_border}>
                {strapiData?.buttons[0]?.title}
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Trial
