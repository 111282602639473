import React from "react"
import * as styles from "./TrustInvoZone.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"

const TrustInvoZone = ({ strapiData }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    speed: 500,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <h2
            className={`${styles.heading} `}
            // dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >
            You Can Count On Us! <br /> More Than 300 Companies Trust InvoZone!
          </h2>
          <div className={styles.topLine} />

          <Row className="align-items-center">
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className={styles.overlay} />
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Slider {...settings} className={styles.devOpsRefineImages}>
                {strapiData?.cards?.map((e, i) => (
                  <div key={i} className={styles.portfoliosLogos}>
                    <img
                      src={e?.image1[0]?.localFile?.publicURL}
                      alt={e?.image1[0]?.alternativeText}
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
          <img
            className={styles.bottomLine}
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_fc76aa81fe.svg"
            loading="lazy"
          />
        </Container>
      </React.Fragment>
    </div>
  )
}

export default TrustInvoZone
