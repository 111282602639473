import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Modal from "react-bootstrap/Modal"
import * as styles from "./Stories.module.scss"
import OliverThumb from "../../images/homeVideoTestimonialsSection/Oliver_Wolff.png"
import RyanThumb from "../../images/homeVideoTestimonialsSection/rayanCarter.webp"
import RizwanThumb from "../../images/homeVideoTestimonialsSection/Rizwan_Hanif-Video_Testimonial.png"
import ChrisThumb from "../../images/homeVideoTestimonialsSection/chris.png"
import OliverAvatar from "../../images/ppc-update/oliverAvatar.png"
import RyanAvatar from "../../images/ppc-update/ryanAvatar.png"
import RizwanAvatar from "../../images/ppc-update/rizwanAvatar.png"
import ChrisAvatar from "../../images/ppc-update/chirsAvatar.png"

export default function Stories({ strapiData }) {
  const data = [
    {
      name: "Oliver Wolff",
      role: "Kinde Product Manager",
      avatar: OliverAvatar,
      thumbnail: OliverThumb,
      video: "eGinGdGXdGA",
    },
    {
      name: "Ryan Carter",
      role: "Shield Republic",
      avatar: RyanAvatar,
      thumbnail: RyanThumb,
      video: "ETvJcozwwzM",
    },
    {
      name: "Rizwan Hanif",
      role: "Xtransform’s CEO",
      avatar: RizwanAvatar,
      thumbnail: RizwanThumb,
      video: "X9uDW_vJmp4",
    },
    {
      name: "Chris Dominguez",
      role: "CEO of StorageChain LLC",
      avatar: ChrisAvatar,
      thumbnail: ChrisThumb,
      video: "SeBRvwbCuks",
    },
  ]

  const [activeStory, setActiveStory] = useState(data[0])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleVideoIconClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <div className={styles.section}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={7}>
              <h2
                className={styles.header}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
            </Col>
            <Col xs={12} md={8} lg={7}>
              <p
                className={styles.desc}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5} lg={4} className={styles.storyCardWrapper}>
              {data?.map(el => (
                <div key={`story_${el.name}`}>
                  <div
                    className={`${styles.storyCard} ${
                      el.name === activeStory.name && styles.active
                    }`}
                    onClick={() => setActiveStory(el)}
                  >
                    <div className={styles.imgContainer}>
                      <img src={el?.avatar} alt={el?.name} />
                    </div>
                    <div className={styles.infoContainer}>
                      <p>{el?.name}</p>
                      <p className={styles.role}>{el?.role}</p>
                    </div>
                  </div>

                  {el.name === activeStory.name && (
                    <div
                      className={`${styles.columnTopBannerImg} d-sm-block d-md-none mb-4`}
                    >
                      <img
                        className={styles.columnImgTop}
                        src={activeStory?.thumbnail}
                        alt=""
                      />
                      <img
                        className={styles.playIconColumn}
                        style={{ cursor: "pointer" }}
                        src={
                          "https://invozone-backend.s3.us-east-1.amazonaws.com/play_button_4d69061cdb.svg"
                        }
                        alt=""
                        onClick={() => handleVideoIconClick()}
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                  )}
                </div>
              ))}
            </Col>
            <Col xs={12} md={7} lg={8}>
              <div
                className={`${styles.columnTopBannerImg2} d-sm-none d-md-block`}
              >
                <img
                  className={styles.columnImgTop}
                  src={activeStory?.thumbnail}
                  alt=""
                />
                <img
                  className={styles.playIconColumn}
                  style={{ cursor: "pointer" }}
                  src={
                    "https://invozone-backend.s3.us-east-1.amazonaws.com/play_button_4d69061cdb.svg"
                  }
                  alt=""
                  onClick={() => handleVideoIconClick()}
                  loading="lazy"
                  decoding="async"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        show={isModalOpen}
        onHide={handleCloseModal}
        keyboard={false}
        centered
        size="lg"
        contentClassName={styles.modalContentTestiRevamp}
      >
        <Modal.Body className={styles.modalBodytestiRevamp}>
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${activeStory?.video}?autoplay=1`}
            allowFullScreen
            title="YouTube video player"
            frameborder="0"
            allow="autoplay"
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
