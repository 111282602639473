// extracted by mini-css-extract-plugin
export var card = "Hitting-module--card--209f9";
export var cards = "Hitting-module--cards--8b34d";
export var description = "Hitting-module--description--361b8";
export var empowerCyberTypescript = "Hitting-module--empowerCyberTypescript--58ea3";
export var expertiseProduct = "Hitting-module--expertiseProduct--fd3fc";
export var hire = "Hitting-module--hire--93244";
export var hireHeading = "Hitting-module--hireHeading--77def";
export var hireImg = "Hitting-module--hireImg--9ee79";
export var productHeading = "Hitting-module--productHeading--a2ba3";
export var talent = "Hitting-module--talent--da11e";
export var text = "Hitting-module--text--27be5";
export var tick = "Hitting-module--tick--7ef32";