import React from "react"
import * as styles from "./ConsultingServices.module.scss"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const ConsultingServices = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div id="devopsServices" className={styles.ServicesWhyAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`gap-30 justify-content-start ${styles.cardWrapper}`}>
            {strapiData?.cards?.map((e, i) => (
              <Col xl={4} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.card}>
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    alt={e?.image1[0]?.alternativeText}
                    decoding="async"
                    loading="lazy"
                  />
                  <h3>{e?.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <div className={`${styles.bannerBtn}`}>
            <Link to="#ppc_form" className={styles.btn}>
              {strapiData?.buttons[0]?.title}
            </Link>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConsultingServices
