import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./KickingOff.module.scss"

export default function KickingOff({ strapiData, bg }) {
  const [active, setActive] = useState(0)

  return (
    <div className={styles.section} style={{ background: bg }}>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div className={styles.contentContainer}>
              <h2 className={styles.header}>
                Kicking Off Your <br />
                Trial Is As Easy As 1 - 2 - 3 - 4
              </h2>
              <p
                className={styles.desc}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
              <div>
                {strapiData?.cards?.map((el, ind) => (
                  <div
                    key={el.id}
                    className={`${styles.processCard} ${
                      active === ind && styles.active
                    }`}
                    onClick={() => setActive(ind)}
                  >
                    <h3 className={styles.subHeading}>{el?.title}</h3>
                    <p
                      className={`${styles.subDesc}  ${
                        active === ind && styles.act
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: el?.subTitle,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={styles.KickImage}
          >
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/kick_Off_Process_81eca2b2f6.svg"
              loading="lazy"
              alt="kicking off"
              className={styles.deskImg}
            />
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/kick_Off_Process_Mobile_2c136fdffc.svg"
              loading="lazy"
              alt="kicking off"
              className={styles.mobileImg}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
