import React from "react"
import * as styles from "./PPCMobile.module.scss"
import Container from "react-bootstrap/Container"
import check from "../../images/ppc-update/check.svg"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PPCForm from "./PPCForm"

const PPCMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.bannerMobileHome}>
        <div>
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <div>
                  <p
                    className={styles.bannerSubtitle}
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.subTitle,
                    }}
                  />
                  <h1 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
                  <p
                    className={styles.dec}
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.description?.description,
                    }}
                  />
                </div>
                <div>
                  {strapiData?.cards &&
                    strapiData?.cards?.map((item, index) => (
                      <div className={styles.talent} key={index}>
                        <img src={check} alt={item?.title} />
                        <p className={styles.text}>{item?.title}</p>
                      </div>
                    ))}
                </div>
              </Col>
              <Col xl={5} md={12} lg={5}>
                <PPCForm />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PPCMobile
