import React, { useState, useEffect } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import check from "../../images/ppc-update/check.svg"
import Vector from "../../images/ppc-update/Vector.svg"
import * as styles from "./Banner.module.scss"
import PPCForm from "./PPCForm"
import "../home-sections/Banner.scss"

const Banner = ({ strapiData }) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  const handleScroll = () => {
    setShow(true)
  }

  return (
    <div className={styles.section}>
      {/* <div className={styles.lineer}></div> */}
      <div className="bannerVideo">
        <div
          dangerouslySetInnerHTML={{
            __html: `<video
          // width="100%"
          // height="100%"
          autoplay
          muted
          loop
          // poster="https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_03_22_at_8_36_34_AM_e5830eac63.png"
        >
          <source
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/output_compress_video_online_com_7d7ef55be0.mp4"
            type="video/mp4"
          >
          Your browser does not support the video tag.
        </video>`,
          }}
        ></div>
      </div>

      <div className={styles.content}>
        <Container>
          <Row className="align-items-center">
            <Col xl={7} md={12} lg={7}>
              <div>
                {/* <img src={Vector}  /> */}
                <div className={styles.bannerSubtitle}>
                  <img src={Vector} alt="icon" />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.subTitle,
                    }}
                  />
                </div>
                <h1>
                  Hire Seasoned <br /> Software Developers.
                </h1>
                <p
                  className={styles.dec}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.description?.description,
                  }}
                />
              </div>
              <div>
                {strapiData?.cards &&
                  strapiData?.cards?.map((item, index) => (
                    <div className={styles.talent} key={index}>
                      <img src={check} alt={item?.title} />
                      <p className={styles.text}>{item?.title}</p>
                    </div>
                  ))}
              </div>
            </Col>
            <Col xl={5} md={12} lg={5}>
              <PPCForm />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default Banner
