import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./HireByRole.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import pmSm from "../../images/ppc-update/hireByRole/pmSm.png"
import uiuxSm from "../../images/ppc-update/hireByRole/uiuxSm.png"
import qaSm from "../../images/ppc-update/hireByRole/qaSm.png"
import baSm from "../../images/ppc-update/hireByRole/baSm.png"
import devOpsSm from "../../images/ppc-update/hireByRole/devOpsSm.png"
import sdSm from "../../images/ppc-update/hireByRole/sdSm.png"
import { Link } from "gatsby"

export default function HireByRole({ strapiData }) {
  return (
    <div className={styles.section}>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={7}>
            <h2
              className={styles.header}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
          </Col>
          <Col xs={12} md={8} lg={7}>
            <p
              className={styles.desc}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            />
          </Col>
        </Row>
        <div className={styles.contentSection}>
          <Link to="#ppc_form" className={styles.btn}>
            Assemble Your Dream Team
          </Link>
          <div className={`${styles.img} ${styles.img1}`}>
            <StaticImage
              loading="lazy"
              src={"../../images/ppc-update/hireByRole/pm.png"}
              alt="pm"
            />
          </div>
          <div className={`${styles.img} ${styles.img2}`}>
            <StaticImage
              loading="lazy"
              src={"../../images/ppc-update/hireByRole/ba.png"}
              alt="ba"
            />
          </div>
          <div className={`${styles.img} ${styles.img3}`}>
            <StaticImage
              loading="lazy"
              src={"../../images/ppc-update/hireByRole/uiux.png"}
              alt="uiux"
              className={styles.img3}
            />
          </div>
          <div className={`${styles.img} ${styles.img4}`}>
            <StaticImage
              loading="lazy"
              src={"../../images/ppc-update/hireByRole/devOps.png"}
              alt="devOps"
              className={styles.img4}
            />
          </div>
          <div className={`${styles.img} ${styles.img5}`}>
            <StaticImage
              loading="lazy"
              src={"../../images/ppc-update/hireByRole/qa.png"}
              alt="qa"
              className={styles.img5}
            />
          </div>
          <div className={`${styles.img} ${styles.img6}`}>
            <StaticImage
              loading="lazy"
              src={"../../images/ppc-update/hireByRole/sse.png"}
              alt="sse"
              className={styles.img6}
            />
          </div>
        </div>
        <div className={styles.contentSectionMobile}>
          <img src={pmSm} alt="pm" />
          <img src={baSm} alt="ba" />
          <img src={uiuxSm} alt="uiux" />
          <img src={sdSm} alt="devOps" />
          <img src={qaSm} alt="qa" />
          <img src={devOpsSm} alt="sse" />
          <Link to="#ppc_form" className={styles.btn}>
            Assemble Your Dream Team
          </Link>
        </div>
      </Container>
    </div>
  )
}
